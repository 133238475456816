import React, { FC, useState, useEffect } from "react";
import { RouteComponentProps } from "@reach/router";
import { LoginProps } from "./App";
import { Participant, Person } from "./Model";
import { lessworkApi } from "./api";
import { AxiosResponse } from "axios";
import LineTo from "react-lineto";
import styled from "styled-components";

interface ViewPersonProps {
  email: string;
  closeDialog: () => void;
}

interface ViewMyProfileProps {
  me: Person;
  closeDialog: () => void;
  deleteCache: () => void;
}

export const ViewPerson: FC<
  RouteComponentProps | LoginProps | ViewPersonProps
> = (props) => {
  const [error, setError] = useState<string>();
  const [graph, setGraph] = useState<Person[]>([]);
  const [participant, setParticipant] = useState<Participant>();
  const [query, setQuery] = useState<String>(props.email);
  const [classNameLastNode, setClassNameLastNode] = useState<string>("Node_0");

  const classNameFirstNode = "Node_0";

  useEffect(() => {
    (async () => {
      const response = await lessworkApi.get(
        `participant?label=${props.label}&query=${query}`
      );
      const json = response.data;
      console.log("Received participant: ", json);
      setParticipant(json.participant);
    })().catch((error) => {
      const response: AxiosResponse<any> | undefined = error.response;
      if (response) {
        console.error("Error", response);
        setError(response.data ? response.data : response.statusText);
      }
    });
  }, [query, props.label]);

  useEffect(() => {
    (async () => {
      const response = await lessworkApi.get(
        `graph?label=${props.label}&query=${query}`
      );
      const json = response.data;
      console.log("Received graph: ", json);
      setGraph(json.userProfileGraph);
    })().catch((error) => {
      const response: AxiosResponse<any> | undefined = error.response;
      if (response) {
        console.error("Error", response);
        setError(response.data ? response.data : response.statusText);
      }
    });
  }, [query, props.label]);

  useEffect(() => {
    if (graph.length > 0) setClassNameLastNode("Node_" + (graph.length - 1));
  }, [graph]);

  console.log("Showing details for: ", props.email);
  let i = 0;
  const items: Array<React.ReactNode> = [];
  graph.forEach((person: Person) => {
    if (i === 0)
      items.push(
        <Node
          className={"Node_" + i}
          key={person.id}
          person={{
            ...person,
            name: "ME",
          }}
          isRoot={true}
          setQuery={setQuery}
        />
      );
    else
      items.push(
        <Node
          className={"Node_" + i}
          key={person.id}
          person={person}
          setQuery={setQuery}
        />
      );

    i++;
  });

  return (
    <Background>
      <LigthBox>
        <CloseButton onClick={() => props.closeDialog()} />
        <Header>
          {participant ? (
            <PersonDetails className="B">
              <ProfileImage />
              <div>
                <div>
                  <Name>{participant.name}</Name>
                  <InviteButton
                    onClick={(e) =>
                      alert(
                        "Feature is coming (near term, Headless only works for Iterate employees)"
                      )
                    }
                  >
                    Invite
                  </InviteButton>
                </div>
                <Email>{participant.email}</Email>
                <Details>
                  It's been{" "}
                  <Duration>{participant.daysSinceLastContact} days</Duration>{" "}
                  since you last had contact
                </Details>
              </div>
            </PersonDetails>
          ) : (
            <></>
          )}
        </Header>
        <Caption>How you were introduced:</Caption>
        <SocialGraph className="A">{items}</SocialGraph>
        <LineTo
          from={classNameFirstNode}
          to={classNameLastNode}
          borderColor="#E4E6EA"
          borderWidth={2}
          zIndex={0}
        />
        {error ? <p>{error}</p> : <></>}
      </LigthBox>
    </Background>
  );
};

const retrieveStats = async (
  setStats: Function,
  setErrorCallback: Function
) => {
  (async () => {
    console.log("Retrieving stats: ");
    const response = await lessworkApi.get(`stats`);
    const json = response.data;
    console.log("Received stats: ", json);
    setStats({
      nrOfTopics: json.nrOfTopics,
      nrOfMailMessages: json.nrOfMailMessages,
      nrOfPeople: json.nrOfPeople,
    });
  })().catch((error) => {
    const response: AxiosResponse<any> | undefined = error.response;
    if (response) {
      console.error("Error getting stats", response);
      setErrorCallback(response.data ? response.data : response.statusText);
    }
  });
};

interface Stats {
  nrOfTopics: number;
  nrOfMailMessages: number;
  nrOfPeople: number;
}

export const ViewMyProfile: FC<ViewMyProfileProps> = (props) => {
  const [stats, setStats] = useState<Stats | undefined>();
  const [error, setError] = useState<string | undefined>();

  useEffect(() => {
    retrieveStats(setStats, setError);
  }, [props.me]);

  return (
    <Background>
      <LigthBox>
        <CloseButton onClick={() => props.closeDialog()} />
        <Header>
          <MyDetails>
            <ProfileImage />
            <div>
              <Name>{props.me.name}</Name>
              {stats ? (
                <Stats>
                  You're active in{" "}
                  <Highlight>{stats.nrOfTopics} topics</Highlight> collaborating
                  with <Highlight>{stats.nrOfPeople} people</Highlight>.
                  <p>
                    Found in {stats.nrOfMailMessages} mail messages and 0 Slack
                    channels.
                  </p>
                </Stats>
              ) : (
                <></>
              )}
            </div>
          </MyDetails>
          {error ? error : <></>}
        </Header>
        <DeleteMe>
          To boost your experience, we have cached email meta data in our secure
          Google Cloud database. You may&nbsp;
          <DeleteButton
            onClick={(e) => {
              var answer = window.confirm("Are you sure?");
              if (answer) props.deleteCache();
            }}
          >
            delete
          </DeleteButton>
          &nbsp;it at any time.
        </DeleteMe>
      </LigthBox>
    </Background>
  );
};

const Stats = styled.div`
  padding-top: 5px;
  font-size: 18px;
  line-height: 20px;
  margin: 0px;
  color: #000;

  & > p {
    font-size: 14px;
    margin: 20px 0 0 0;
    color: #6a6a6a;
  }
`;

const Background = styled.div`
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  background: rgba(0, 0, 0, 0.5);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const LigthBox = styled.div`
  position: relative;
  width: 80%;
  height: 70%;
  background: #fff;
  border-radius: 30px;
  padding: 10px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 190px;
  align-items: stretch;
  justify-content: space-between;
  margin-bottom: 40px;
  border-bottom: 1px solid #e4e6ea;
`;

const Name = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin: 0px 10px 2px 0px;
`;
const Email = styled.div`
  margin: 0px 0px 19px 0px;
  font-size: 18px;
  font-weight: normal;
  line-height: 25px;
  letter-spacing: -0.01em;
  color: #6a6a6a;
`;

const Details = styled.p`
  font-size: 14px;
  line-height: 20px;
  margin: 0px;
  color: #000;
`;

const ProfileImage = styled.div<{ imageUrl?: string }>`
  background: ${(props) =>
    props.imageUrl ? 'url("' + props.imageUrl + '")' : "none"};
  border: 1px solid #e4e6ea;
  width: 96px;
  height: 96px;
  border-radius: 96px;
  margin-right: 30px;
`;

const InviteButton = styled.button`
  width: 60px;
  height: 25px;
  border: none;
  border-radius: 5px;
  background: #88dea5;
`;

const Caption = styled.div`
  margin: 0 0 40px 40px;
  color: #6a6a6a;
  font-size: 14px;
`;

const Duration = styled.span`
  width: 60px;
  height: 24px;
  background: #e4e6ea;
  border-radius: 20px;
  padding: 4px 8px;
`;
const PersonDetails = styled.div`
  display: flex;
  height: 150px;
  margin: 40px 0px 0px 40px;

  & > div > div {
    display: flex;
  }
`;

const CloseButton = styled.div`
  position: absolute;
  right: 10px;
  width: 50px;
  height: 50px;
  background-image: url("/images/button-close.svg");
  background-repeat: none;
`;

const DeleteButton = styled.button`
  margin: 0;
  padding: 0;
  height: 25px;
  border: none;
  background: none;
  color: #ff3a3a;
  text-decoration: underline;
  font-weight: bold;
`;

const Highlight = styled.span`
  padding: 3px 8px;
  height: 24px;
  background: #88dea5;
  border-radius: 20px;
`;

const DeleteMe = styled.p`
  position: absolute;
  left: 35px;
  bottom: 35px;
  font-size: 14px;
  color: #6a6a6a;
`;

const MyDetails = styled.div`
  display: flex;

  height: 150px;
  margin: 40px 0px 0px 40px;
`;

interface AvatarProps {
  person: Person;
  imageUrl?: string;
  className: string;
  isRoot?: boolean;
  setQuery: (string) => void;
}

const Node: FC<AvatarProps> = (props) => {
  console.log("Rendering Node: ", props);
  return (
    <NodeDiv
      onClick={(e) => {
        console.log("Viewing? ", props.person.email);
        props.setQuery(props.person.email);
      }}
    >
      {props.imageUrl !== undefined ? (
        <AvatarDiv className={props.className} imageUrl={props.imageUrl} />
      ) : (
        <GenericAvatar className={props.className} isRoot={props.isRoot} />
      )}
      <Label>
        {props.person.name ? props.person.name : props.person.email}
      </Label>
    </NodeDiv>
  );
};

const SocialGraph = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const NodeDiv = styled.div`
  display: flex;
  width: 180px;
  flex-direction: column;
  align-items: center;
`;
const AvatarDiv = styled.div<{ imageUrl: string }>`
  background-image: url(${(props) => props.imageUrl});
`;
const GenericAvatar = styled.div<{ isRoot?: boolean }>`
  background: ${(props) => (props.isRoot ? "#C7CCFF" : "#E4E6EA")};
  height: 70px;
  width: 70px;
  border-radius: 70px;
  z-index: 50;
`;
const Label = styled.div`
  margin-top: 10px;
`;
