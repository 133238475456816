import React, { useState, useEffect } from "react";
import { Router } from "@reach/router";
import "./App.css";
import "normalize.css";
import { lessworkApi } from "./api";
import { AxiosResponse } from "axios";
import { Person } from "./Model";
import Home from "./Home";
import Scan from "./Scan";

export interface UserSession {
  user?: Person;
}

export interface LoginProps {
  userSession: UserSession;
  label: string;
}

const retrieveUserProfile = async (setUserSession: Function) => {
  (async () => {
    const response = await lessworkApi.get(`user`);
    console.log(response);
    const json = response.data;
    console.log("Received user profile: ", json);
    setUserSession({ user: { ...json } });
  })().catch((error) => {
    const response: AxiosResponse<any> | undefined = error.response;
    if (response) {
      const status = response.status;
      if (status === 401) {
        console.log("User not logged in, continuing without user session");
      } else {
        console.error("Error getting user profile: ", response);
      }
    }
  });
};

const App: React.FC = () => {
  const [userSession, setUserSession] = useState<UserSession>({});

  useEffect(() => {
    retrieveUserProfile(setUserSession);
  }, []);

  return (
    <Router>
      <Home path="/" userSession={userSession} />
      <Scan path="/scan" userSession={userSession} label={"headless"} />
    </Router>
  );
};

export default App;
