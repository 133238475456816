import React from "react";
import styled from "styled-components";

interface MapMenuProps {
  zoomIn: () => void;
  zoomOut: () => void;
}

export const MapMenu: React.FC<MapMenuProps> = (props) => {
  return (
    <MenuWrapper>
      <MenuStyle>
        <Button
          imageUrl={"/images/zoom_in.svg"}
          onClick={() => props.zoomIn()}
        />
        <Button
          imageUrl={"/images/zoom_out.svg"}
          onClick={() => props.zoomOut()}
        />
      </MenuStyle>
    </MenuWrapper>
  );
};

const MenuWrapper = styled.div`
  top: 160px;
  width: 60px;
  height: 110px;
  left: 10%;
  position: fixed;
  z-index: 1;
  background: #fff;
  border-radius: 50px;

  @media (max-width: 1050px) {
    left: 2.5%;
  }
`;

const MenuStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #e5e5e5;
  color: black;
  margin: 5px;
  border-radius: 50px;
`;

export const Button = styled.div<{ imageUrl: string }>`
  background-image: url(${(props) => props.imageUrl});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 24px 24px;
  height: 50px;
  width: 50px;
`;
