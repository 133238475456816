import axios from "axios";

enum Environment {
  Localhost,
  Test,
  Production,
}

export const resolveEnvironment = (): Environment => {
  if (window.location.hostname === "localhost") {
    return Environment.Localhost;
  }
  if (window.location.hostname.endsWith(".test.iterate.no")) {
    return Environment.Test;
  }
  return Environment.Production;
};

export const lessworkApiAddress = (() => {
  var environment = resolveEnvironment();
  if (environment === Environment.Localhost) {
    return "http://localhost:8080";
  }
  if (environment === Environment.Test) {
    return "https://lesswork-backend.test.iterate.no";
  }
  return "https://lesswork-backend.app.iterate.no";
})();

export const lessworkApi = axios.create({
  withCredentials: true,
  baseURL: lessworkApiAddress,
});

export function getBasePath(): string {
  const env = resolveEnvironment();
  switch (env) {
    case Environment.Localhost:
      return "http://localhost:3000";
    case Environment.Test:
      return "https://lesswork-frontend.test.iterate.no";
    default:
      return "https://lesswork-frontend.app.iterate.no";
  }
}
