import { Participant } from "../Model";

export enum ElementType {
  Avatar,
  Whitespace_Half,
  Whitespace_Full,
}

export interface Element {
  id: string;
  participant?: Participant;
  type: ElementType;
}

export class BubbleLayout {
  bubbleLayouts = {
    3: [3],
    10: [3, 4, 3],
    19: [3, 4, 5, 4, 3],
    30: [3, 4, 5, 6, 5, 4, 3],
    43: [3, 4, 5, 6, 7, 6, 5, 4, 3],
    58: [3, 4, 5, 6, 7, 8, 7, 6, 5, 4, 3],
    75: [3, 4, 5, 6, 7, 8, 9, 8, 7, 6, 5, 4, 3],
  };

  idCounter: number = 0;

  layoutPattern(nrOfNodes: number) {
    for (let key in this.bubbleLayouts)
      if (nrOfNodes <= +key) return this.bubbleLayouts[key];
    return this.bubbleLayouts[75];
  }

  nrOfRows(nrOfNodes: number) {
    return Math.max(...this.layoutPattern(nrOfNodes));
  }

  nrOfCols(nrOfNodes: number) {
    return this.layoutPattern(nrOfNodes).length;
  }

  buildWhiteSpace(nrOfNodes: number) {
    let result: Array<Element> = [];
    let nrOfFullNodes: number;
    if (nrOfNodes % 1 !== 0) {
      result.push({
        id: "WH_" + this.idCounter++,
        type: ElementType.Whitespace_Half,
      });
      nrOfFullNodes = Math.round(nrOfNodes) - 1;
    } else nrOfFullNodes = nrOfNodes;

    for (let i = 0; i < nrOfFullNodes; i++) {
      result.push({
        id: "WF_" + this.idCounter++,
        type: ElementType.Whitespace_Full,
      });
    }
    return result;
  }

  buildElementList(id: number, avatars: Array<Participant | undefined>) {
    let columnLayout = this.layoutPattern(avatars.length);
    let rowCount = this.nrOfRows(avatars.length);
    let colCount = this.nrOfCols(avatars.length);

    let childrenIndex = 0;
    let elements: Array<Element> = [];

    for (var columnIndex = 0; columnIndex < colCount; columnIndex++) {
      let nrOfNodesInColumn = columnLayout[columnIndex];
      let nrOfEmptyNodes = (rowCount - nrOfNodesInColumn) / 2;

      let whiteSpace: Element[] = this.buildWhiteSpace(nrOfEmptyNodes);
      elements = elements.concat(whiteSpace);

      for (var rowIndex = 0; rowIndex < nrOfNodesInColumn; rowIndex++) {
        if (childrenIndex < avatars.length) {
          elements.push({
            id: "A_" + columnIndex + "_" + rowIndex,
            participant: avatars[childrenIndex],
            type: ElementType.Avatar,
          });
          childrenIndex++;
        } else {
          elements.push({
            id: "E_" + columnIndex + "_" + rowIndex,
            type: ElementType.Whitespace_Full,
          });
        }
      }
      whiteSpace = this.buildWhiteSpace(nrOfEmptyNodes);
      elements = elements.concat(whiteSpace);
    }
    return elements;
  }
}

export default BubbleLayout;
