import React, { FC, useState, useEffect, useRef } from "react";
import { RouteComponentProps } from "@reach/router";
import { AxiosResponse } from "axios";
import styled from "styled-components";
import { Space } from "react-zoomable-ui";
import LineTo from "react-lineto";
import { LoginProps } from "./App";
import { ViewPerson, ViewMyProfile } from "./ViewPerson";
import { lessworkApi } from "./api";
import { Login } from "./Login";
import { Person, Topic, Participant } from "./Model";
import { Menubar } from "./Menu";
import { MapMenu } from "./MapMenu";
import { CirclesLayout } from "./layouts/CirclesLayout";
import { BubbleLayout, Element, ElementType } from "./layouts/BubbleLayout";

const retrieveTopics = async (
  label: string,
  setTopics: Function,
  setErrorCallback: Function,
  force?: boolean
) => {
  (async () => {
    const response = await lessworkApi.get(
      `topics?label=${label}&force=${force ? force : false}`
    );
    const json = response.data;
    console.log("Received topics: ", json);
    setTopics(json.topics);
  })().catch((error) => {
    const response: AxiosResponse<any> | undefined = error.response;
    if (response) {
      console.error("Error", response);
      setErrorCallback(response.data ? response.data : response.statusText);
    }
  });
};

const retrieveConnectionIds = async (
  connectionId: number,
  address: string,
  setConnectionId: Function,
  setConnectionIds: Function,
  setErrorCallback: Function
) => {
  (async () => {
    const response = await lessworkApi.get(
      `connections?email=${address}&nodeId=${connectionId}`
    );
    const json = response.data;
    console.log("Received connection ids: ", json);
    setConnectionIds(json.participantIds);
    setConnectionId(connectionId);
  })().catch((error) => {
    const response: AxiosResponse<any> | undefined = error.response;
    if (response) {
      console.error("Error", response);
      setErrorCallback(response.data ? response.data : response.statusText);
    }
  });
};

const deleteCache = async (setErrorCallback: Function) => {
  (async () => {
    const response = await lessworkApi.get(`deleteCache`);
    const json = response.data;
    console.log("Cache deleted: ", json);
  })().catch((error) => {
    const response: AxiosResponse<any> | undefined = error.response;
    if (response) {
      console.error("Error deleting cache", response);
      setErrorCallback(response.data ? response.data : response.statusText);
    }
  });
};

export const Scan: FC<RouteComponentProps | LoginProps> = (props) => {
  const [topics, setTopics] = useState<Topic[]>([]);
  const [connectionIds, setConnectionIds] = useState<Array<number>>([]);
  const [connectionId, setConnectionId] = useState<number | null>(null);
  const [selectedPerson, setSelectedPerson] = useState<Person | undefined>();
  const [showMyProfile, setShowMyProfile] = useState<boolean>(false);
  const [cacheDeleted, setCacheDeleted] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  useEffect(() => {
    retrieveTopics(parseLabel(props), setTopics, setError);
  }, []);

  const forceScan = () => {
    retrieveTopics(parseLabel(props), setTopics, setError, true);
  };

  const parseLabel = (props: RouteComponentProps) => {
    let label = new URLSearchParams(props.location.search).get("label");
    return label ? label : "";
  };

  const hideConnections = () => {
    setConnectionId(null);
    setConnectionIds([]);
  };

  const viewPerson = (person: Person) => {
    hideConnections();
    setSelectedPerson(person);
  };

  const hidePerson = () => {
    setShowMyProfile(false);
    setSelectedPerson(undefined);
  };

  const topicItems: Array<React.ReactNode> = [];
  let circlesLayout = new CirclesLayout(450);

  topics.forEach((topic: Topic) => {
    let placement = circlesLayout.placeNextItem();
    topicItems.push(
      <Cluster
        key={topic.id}
        setConnectionId={setConnectionId}
        setConnectionIds={setConnectionIds}
        hideConnections={hideConnections}
        setErrorCallback={setError}
        viewPerson={viewPerson}
        topic={topic}
        rotation={placement.rotation}
        radius={placement.radius + "px"}
      />
    );
  });

  const circles: Array<React.ReactNode> = [];
  circlesLayout.circlesUsed.forEach((radius: number) => {
    circles.push(<Circle key={"C_" + radius} radius={radius} />);
  });

  let connectionLines: Array<React.ReactNode> = [];
  if (connectionId) {
    connectionIds.forEach((id: number) =>
      connectionLines.push(
        <LineTo
          from={"Avatar_P" + connectionId}
          to={"Avatar_P" + id}
          borderColor="#000"
        />
      )
    );
  }

  const r = React.useRef<Space | null>(null);

  return (
    <Login userSession={props.userSession}>
      <NetworkMapDiv>
        <Menubar
          hideConnections={hideConnections}
          triggerRescan={forceScan}
          showMyProfile={() => {
            hideConnections();
            setShowMyProfile(true);
            setSelectedPerson(props.userSession.user);
          }}
        />
        <Space ref={r}>
          {topics?.length === 0 ? (
            error ? (
              <ErrorMessage>{error}</ErrorMessage>
            ) : (
              <LoadingMessage>
                {cacheDeleted ? (
                  <p>
                    Please <Link onClick={forceScan}>rescan</Link> to see your
                    topics
                  </p>
                ) : (
                  "Graciously scanning your email ..."
                )}
              </LoadingMessage>
            )
          ) : (
            <Centered>
              {topicItems} {circles}
            </Centered>
          )}
          {selectedPerson !== undefined ? <></> : <>{connectionLines}</>}
        </Space>
        {selectedPerson !== undefined ? (
          showMyProfile ? (
            <ViewMyProfile
              me={props.userSession.user}
              deleteCache={() => {
                deleteCache(setError);
                console.log("TODO: invoke deletingCache");
                setTopics([]);
                setSelectedPerson(undefined);
                setShowMyProfile(false);
                setCacheDeleted(true);
              }}
              closeDialog={() => hidePerson()}
            />
          ) : (
            <ViewPerson
              label={parseLabel(props)}
              email={selectedPerson.email}
              closeDialog={() => hidePerson()}
            />
          )
        ) : (
          <MapMenu
            zoomIn={() => {
              r.current?.viewPort?.camera.moveBy(0, 0, 0.1);
            }}
            zoomOut={() => {
              r.current?.viewPort?.camera.moveBy(0, 0, -0.1);
            }}
          />
        )}
      </NetworkMapDiv>
    </Login>
  );
};

const Link = styled.button`
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  color: #000;
  text-decoration: underline;
  font-weight: bold;
`;

const Centered = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
`;

const Circle = styled.div<{ radius: number }>`
  position: absolute;
  z-index: 1;
  border-radius: ${(props) => props.radius + "px"};
  left: 0px;
  top: 0px;
  width: ${(props) => 2 * props.radius + "px"};
  height: ${(props) => 2 * props.radius + "px"};
  transform: translate(
    ${(props) => -1 * props.radius + "px"},
    ${(props) => -1 * props.radius + "px"}
  );
  border: 1px solid #c7ccff;
`;

const NetworkMapDiv = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 0;
`;

interface ClusterProps {
  topic: Topic;
  radius: string;
  rotation: number;
  viewPerson: (Person) => void;
  setConnectionIds: (Array) => void;
  setConnectionId: (number) => void;
  hideConnections: () => void;
  setErrorCallback: (String) => void;
}

const Cluster: FC<ClusterProps> = (props) => {
  if (props.topic === undefined) return <div />;
  let bl = new BubbleLayout();
  let participants: Array<Participant> = [];
  props.topic.participants.forEach((participant) => {
    participants.push(participant);
  });
  let avatars = bl.buildElementList(props.topic.id, participants);

  let bubbleHeight: number = bl.nrOfRows(participants.length) * 60;
  let bubbleWidth: number = bl.nrOfCols(participants.length) * 60;

  const labelHeight: number = 30;
  const paddingHorizontal = 20;
  const paddingVertical = 40;

  const showConnections = (participantId: number) => {
    let participant: Participant | undefined;
    participant = participants.find((p) => p.id === participantId);
    if (participant) {
      retrieveConnectionIds(
        participantId,
        participant.email,
        props.setConnectionId,
        props.setConnectionIds,
        props.setErrorCallback
      );
    } else {
      console.error("Could not find participant among: ", participants);
    }
  };

  let width: number = bubbleWidth + 100;
  let height: number = bubbleHeight + labelHeight + 60;
  let posX = 0 - width / 2;
  let posY = 0 - height / 2;

  return props.topic === undefined ? (
    <div />
  ) : (
    <ClusterDiv
      rotation={props.rotation}
      translation={props.radius}
      left={posX + "px"}
      top={posY + "px"}
      width={width + "px"}
      height={height + "px"}
    >
      <LabelDiv height={labelHeight + "px"}>{props.topic.name}</LabelDiv>
      <AvatarGroupDiv
        width={bubbleWidth + paddingHorizontal + "px"}
        height={bubbleHeight + paddingVertical + "px"}
        borderRadius={bl.nrOfCols(participants.length) * 40 + "px"}
      >
        {avatars.map((avatar) => (
          <ClusterNode
            showConnections={showConnections}
            hideConnections={props.hideConnections}
            className={
              "Avatar_" +
              (avatar.participant ? "P" + avatar.participant.id : avatar.id)
            }
            key={avatar.id}
            element={avatar}
            width={60}
            height={60}
            viewPerson={props.viewPerson}
          />
        ))}
      </AvatarGroupDiv>
    </ClusterDiv>
  );
};

interface DimensionProps {
  readonly left?: string;
  readonly top?: string;
  readonly width?: string;
  readonly height?: string;
  readonly borderRadius?: string;
  readonly translation?: string;
  readonly rotation?: number;
}

const WhiteSpaceDiv = styled.div<DimensionProps>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background: rgba(0, 0, 0, 0);
`;

const ClusterDiv = styled.div<DimensionProps>`
  position: absolute;
  z-index: 2;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  left: ${(props) => props.left};
  top: ${(props) => props.top};

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  transform: rotate(${(props) => props.rotation + "deg"})
    translate(${(props) => props.translation})
    rotate(
      ${(props) => (props.rotation ? -1 * props.rotation + "deg" : "0deg")}
    );
`;

const LabelDiv = styled.div<DimensionProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 160px;
  height: ${(props) => props.height};
  background: #88dea5;
  border-radius: 5px;
`;

const AvatarGroupDiv = styled.div<DimensionProps>`
  position: relative;
  z-index: 2;
  width: ${(props) => props.width};
  height: ${(props) => props.height};

  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;

  border-radius: ${(props) => props.borderRadius};

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  background: #d9d9d9;
`;

interface ClusterNodeProps {
  height: number;
  width: number;
  element: Element;
  className: string;
  viewPerson: (Person) => void;
  showConnections: (number) => void;
  hideConnections: () => void;
}

const ClusterNode: FC<ClusterNodeProps> = (props) => {
  switch (props.element.type) {
    case ElementType.Avatar:
      return <Avatar {...props} />;
    case ElementType.Whitespace_Half:
      return (
        <WhiteSpaceDiv
          width={props.width + "px"}
          height={props.height / 2 + "px"}
        />
      );
    case ElementType.Whitespace_Full:
      return (
        <WhiteSpaceDiv
          width={props.width + "px"}
          height={props.height + "px"}
        />
      );
  }
};

const Avatar: FC<ClusterNodeProps> = (props) => {
  const [showDetails, setShowDetails] = useState<boolean>(false);
  return (
    <AvatarParentDiv width="50px" height="50px">
      {showDetails ? (
        <AvatarDetailsDiv
          onMouseLeave={(event) => {
            setShowDetails(false);
            props.hideConnections();
          }}
          onClick={(e) => props.viewPerson(props.element.participant)}
          borderRadius="54px"
          width="250px"
          height="54px"
        >
          <AvatarDiv
            className={props.className}
            width="50px"
            height="50px"
            key={props.element.participant?.id + "_double"}
          >
            <AvatarInitials title={props.element.participant?.name}>
              {props.element.participant?.initials}
            </AvatarInitials>
          </AvatarDiv>
          <AvatarNameAndCompany>
            <h4>{props.element.participant?.name}</h4>
            <p>{props.element.participant?.company}</p>
          </AvatarNameAndCompany>
        </AvatarDetailsDiv>
      ) : (
        <></>
      )}
      <AvatarDiv
        onMouseEnter={(e) => {
          setShowDetails(true);
          props.showConnections(props.element.participant?.id);
        }}
        className={props.className}
        width="50px"
        height="50px"
        key={props.element.participant?.id}
      >
        <AvatarInitials title={props.element.participant?.name}>
          {props.element.participant?.initials}
        </AvatarInitials>
      </AvatarDiv>
    </AvatarParentDiv>
  );
};

const AvatarParentDiv = styled.div<DimensionProps>`
  position: relative;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  margin: 5px;
  z-index: 5;
`;
const AvatarDiv = styled.div<DimensionProps>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border-radius: ${(props) => props.width};
  background: #c7ccff;
  margin: 0;
  padding: 0;
  text-align: center;
`;

const AvatarDetailsDiv = styled.div<DimensionProps>`
  position: absolute;
  z-index: 7;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  left: -2px;
  top: -2px;
  padding: 2px;
  border-radius: ${(props) => props.borderRadius};
  background: #fff;
`;

const AvatarNameAndCompany = styled.div`
  margin-left: 10px;
  max-width: 180px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  & > h4 {
    color: #6a6a6a;
    font-weight: bold;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  & > p {
    margin: 0;
    font-size: 14px;
    color: #6a6a6a;
  }
`;

const AvatarInitials = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingMessage = styled.div`
  position: absolute;
  top: 45%;
  left: 40%;
  width: 20%;
  height: 10%;
  z-index: 5;
  background: #d9d9d9;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 60px;
`;

const ErrorMessage = styled.div`
  position: absolute;
  top: 35%;
  left: 20%;
  width: 80%;
  height: 15%;
  z-index: 10;
  background: #ff3a3a;

  padding-left: 20px;
  padding-right: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 60px;
`;

export default Scan;
