import React, { FC, useState } from "react";
import styled from "styled-components";
import { lessworkApi } from "./api";
import { AxiosResponse } from "axios";

const retrieveProfile = async (
  setStatus: Function,
  setErrorCallback: Function
) => {
  (async () => {
    // const response = await lessworkApi.get(`scan?label=${label}`);
    const response = await lessworkApi.get(`status`);
    const json = response.data;
    console.log("Received status: ", json);
    setStatus({
      threadsTotal: json.threadsTotal,
      messagesTotal: json.messagesTotal,
      threadsScanned: json.threadsScanned,
      lastScan: json.lastScan,
    });
  })().catch((error) => {
    const response: AxiosResponse<any> | undefined = error.response;
    if (response) {
      console.error("Error", response);
      setErrorCallback(response.data ? response.data : response.statusText);
    }
  });
};

interface Status {
  threadsTotal: number;
  messagesTotal: number;
  threadsScanned: number;
  lastScan: string;
}

export const Menubar: React.FC<{
  hideConnections: () => void;
  triggerRescan: () => void;
  showMyProfile: () => void;
}> = (props) => {
  const [showNotifications, setShowNotifications] = useState<boolean>(false);
  const [status, setStatus] = useState<Status | undefined>();
  const [error, setError] = useState<string>();

  const toggleNotificationsMenu = () => {
    if (!showNotifications) {
      props.hideConnections();
      initializeNotifications();
    }
    setShowNotifications(!showNotifications);
  };

  const initializeNotifications = () => {
    retrieveProfile(setStatus, setError);
  };

  return (
    <MenuWrapper>
      <MenuStyle>
        <Logo>
          <LogoImage />
          Headless
        </Logo>
        <Features>
          <li style={{ color: "black" }}>Network</li>
          <li>Workbench</li>
          <li>Discover</li>
          <li>Go on a trip!</li>
        </Features>
        <Buttons>
          {error ? <InfoPaneError>{error}</InfoPaneError> : <></>}
          <NotificationsIcon onClick={toggleNotificationsMenu} />
        </Buttons>
      </MenuStyle>
      {showNotifications ? (
        <NotificationsMenu>
          <NotificationsHeading>
            <h1>Notifications</h1>
            <ProfileButton
              onClick={(e) => {
                setShowNotifications(false);
                props.showMyProfile();
              }}
            >
              My Profile
            </ProfileButton>
          </NotificationsHeading>
          <InfoPane>
            <InfoPaneIcon icon={"images/plus-icon.svg"} />
            <InfoPaneContent>
              <h2>Scanning your email</h2>
              <p>We're helping you discover your network.</p>
              {status?.threadsTotal ? (
                <ProgressBar
                  maxValue={status?.threadsTotal}
                  currentValue={status.threadsScanned}
                />
              ) : (
                <InfoPaneError>Scanning status unavailable.</InfoPaneError>
              )}
              <p>Last scan: {status?.lastScan}.</p>
              <p>
                We scan a new chunk once a day when you log in. Impatient? Scan
                more&nbsp;
                <ScanButton
                  onClick={(e) => {
                    props.triggerRescan();
                    setShowNotifications(false);
                  }}
                >
                  now
                </ScanButton>
                .
              </p>
            </InfoPaneContent>
          </InfoPane>
        </NotificationsMenu>
      ) : (
        <></>
      )}
    </MenuWrapper>
  );
};

const ProgressBar: FC<{ maxValue: number; currentValue: number }> = (props) => {
  let completionRate: number = (props.currentValue / props.maxValue) * 100;
  completionRate = Math.round(completionRate * 100) / 100;
  return (
    <svg viewBox="0 0 100 15" xmlns="http://www.w3.org/2000/svg">
      <rect
        width="100%"
        height="15px"
        stroke="#000"
        fill="none"
        strokeWidth="0.5"
      />
      <rect width={completionRate + "%"} height="15px" fill="#000" />
      <text x="2" y="9" style={{ font: "4px sans-serif", fill: "black" }}>
        {completionRate + "%"}
      </text>
    </svg>
  );
};

const InfoPane = styled.div`
  margin-top: 21px;
  padding-top: 24px;
  border-top: 1px solid #e5e5e5;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 356px;
`;

const InfoPaneIcon = styled.div<{ icon: string }>`
  background-image: url(${(p) => p.icon});
  background-repeat: no-repeat;
  background-size: 50px;
  width: 50px;
  height: 50px;
  padding: 0px;
`;

const InfoPaneError = styled.span`
  font-family: Messina Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
  color: #ff3a3a;
`;

const InfoPaneContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 296px;

  & > h2 {
    margin: 0px;
    font-family: Messina Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
  }
  & > p {
    font-family: Messina Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
  }
  & > p > a {
    color: #ff3a3a;
  }
`;

const ScanButton = styled.button`
  background: rgba(0, 0, 0, 0);
  border-radius: 5px;
  border: 0;
  font-weight: bold;
  text-decoration: underline;
  padding: 0;
`;

const NotificationsMenu = styled.div`
  width: 396px;
  height: 600px;
  background: #ffffff;
  opacity: 0.99;
  border-radius: 30px;
  padding: 20px;
  margin-top: 20px;
`;

const NotificationsHeading = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  & > h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 29px;
    letter-spacing: 0.01em;
  }
`;

const ProfileButton = styled.button`
  width: 151px;
  height: 36px;

  background: none;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 5px;

  font-size: 14px;
  line-height: 20px;
  text-align: center;
`;

const MenuWrapper = styled.div`
  top: 60px;
  width: 80%;
  left: 10%;
  position: fixed;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const MenuStyle = styled.div`
  display: flex;
  flex-direction: horizontal;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  width: 100%;
  min-width: 600px;
  height: 60px;
  border-radius: 50px;
  background: white;
  color: black;

  @media (max-width: 1050px) {
    width: 95%;
  }
`;

const Logo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 5px;
  font-weight: bold;
`;
const Features = styled.ul`
  display: flex;
  flex-direction: horizontal;
  justify-content: space-between;
  list-style-type: none;
  & > li {
    color: #c1c1c1;
    margin-right: 40px;
    white-space: nowrap;
  }
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  min-width: 200px;
  margin-right: 5px;
`;

export const LogoImage = styled.div`
  background-image: url("/images/logo.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 120px 66px;
  border-radius: 50px;
  height: 50px;
  width: 50px;
  margin-right: 10px;
`;

const NotificationsIcon = styled.div`
  background-image: url("/images/notifications_icon.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 120px 66px;
  border-radius: 50px;
  height: 50px;
  width: 50px;
`;
