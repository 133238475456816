import React, { FC } from "react";
import styled from "styled-components";
import { lessworkApiAddress, getBasePath } from "./api";
import { UserSession } from "./App";

interface LoginProps {
  children: React.ReactNode;
  userSession: UserSession;
}

export const Login: FC<LoginProps> = (props) => {
  const basePath = getBasePath();
  return props.userSession.user ? (
    <>{props.children}</>
  ) : (
    <Main>
      <LogoPart>
        <img alt="Headless Logo" src="/images/logo.svg" />
        <p>Headless</p>
      </LogoPart>
      <LoginDiv>
        <h2>Sign in</h2>
        <h1>Next generation networking</h1>
        <p>
          We would like to create a map of you network by scanning your email
          metadata. We will ask for read access to your Gmail. No changes will
          be made to your emails.
        </p>
        <ButtonWithCaption>
          <a
            href={`${lessworkApiAddress}/login?redirect_uri=${basePath}/scan`}
            rel="noopener noreferrer"
          >
            <GoogleLogin>Log in</GoogleLogin>
          </a>
          <p>
            Sensitive data will be encrypted, and stored on a secure Google
            Cloud server.
          </p>
        </ButtonWithCaption>
      </LoginDiv>
    </Main>
  );
};

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: #f1f1f1;
  color: #000;
`;

const LogoPart = styled.div`
  position: absolute;
  top: 25px;
  left: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;

  & > p {
    margin-left: 8px;
    font-family: Messina Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
  }
  & > img {
    height: 50px;
    width: 50px;
  }
`;

const LoginDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 28px;
  height: 470px;
  width: 300px;
  background: #fff;
  color: #000;
  border-radius: 30px;

  & > h1 {
    width: 100%;
    font-family: Messina Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 29px;
    letter-spacing: 0.01em;
    margin: 0px;
  }

  & > h2 {
    width: 100%;
    font-family: Messina Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.01em;
    color: #7e7e7e;
  }

  & > p {
    width: 100%;
    margin-top: 50px;
    font-size: 16px;
    line-height: 20px;
    color: #000;
  }
`;

const ButtonWithCaption = styled.div`
  width: 100%;

  & > a {
    text-decoration: none;
  }

  & > p {
    font-family: Messina Sans;
    font-size: 12px;
    color: #7e7e7e;
  }
`;

const GoogleLogin = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 236px;
  height: 60px;
  border-radius: 5px;
  background: #88dea5;

  font-family: Messina Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
`;
