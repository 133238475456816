import React, { FC } from "react";
import { RouteComponentProps, Link } from "@reach/router";
import { Login } from "./Login";
import { UserSession } from "./App";

interface LoginProps {
  userSession: UserSession;
}

export const Home: FC<RouteComponentProps | LoginProps> = (props) => {
  return (
    <Login userSession={props.userSession}>
      <div className="App">
        <header className="App-header">
          <h1>Welcome</h1>
          <p>{props.userSession.user?.name}</p>
          <Link to={"/scan"}>Rediscover your network</Link>
        </header>
      </div>
    </Login>
  );
};

export default Home;
