export class CirclesLayout {
  circleCapacity: Array<number> = [
    1,
    6,
    12,
    24,
    36,
    48,
    60,
    70,
    80,
    90,
    110,
    130,
    150,
  ];

  circleIndex = 0;
  itemsInCircle: number = 0;
  radius: number = 0;
  radiusGrowthFactor: number;

  rotation: number = 0;

  start: boolean = true;
  public circlesUsed: Array<number> = [];

  constructor(radiusGrowthFactor: number) {
    this.radiusGrowthFactor = radiusGrowthFactor;
  }

  placeNextItem() {
    if (this.start) {
      this.start = false;
      this.itemsInCircle++;
      return { radius: 0, rotation: 0 };
    }
    if (this.itemsInCircle >= this.circleCapacity[this.circleIndex] - 1) {
      if (this.radius > 0) this.circlesUsed.push(this.radius);
      this.itemsInCircle = 0;
      this.circleIndex++;
      this.radius += this.radiusGrowthFactor;
      this.rotation = 0;
    } else {
      this.itemsInCircle++;
      this.rotation += 360 / this.circleCapacity[this.circleIndex];
    }
    return { radius: this.radius, rotation: this.rotation };
  }
}
